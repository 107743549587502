import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const NewUserRegisterForUnitTrust = () => {
  return (
    <Layout>
      <Seo
        title="How Can a New User Register for Unit Trust"
        description={`Registering for a Unit Trust Profile as a new user in the Synkli mobile app is easy.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`new-user-register-for-unit-trust`} />
    </Layout>
  )
}

export default NewUserRegisterForUnitTrust
